import React from 'react'
import { graphql } from 'gatsby'
import Meta from '../components/meta'
import get from 'lodash/get'
import Section from '../components/section'
import Layout from '../components/layout'

class PageTemplate extends React.Component {
  render() {
    const page = get(this.props, 'data.contentfulPage', {})
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const defaultMeta = get(this.props, 'data.site.siteMetadata')

    const metaTitle = page.metaTitle || page.headline || siteTitle
    const metaDescription = page.metaDescription || defaultMeta.description
    
    return (
      <Layout location={this.props.location}>
        <div className={`page--${page.slug}`}>
          <Meta
            defaultMeta={defaultMeta}
            title={metaTitle}
            description={metaDescription}
          />
          {page.sections && page.sections.map((section) => (
            <Section {...section} key={section.id} />
          ))}
        </div>
      </Layout>
    )
  }
}


export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        description
        siteUrl
        image
        socialInstagram
        socialTwitter
      }
    }
    contentfulPage(slug: {eq: $slug}) {
      headline
      metaTitle
      metaDescription
      sections {
        id
        heading
        theme
        variant
        ctaLabel
        ctaLink {
          slug
        }
        body {
          json
        }
      }
    }
  }
`

